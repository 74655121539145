@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-phone-input-2/lib/style.css';
@import '~react-bootstrap-typeahead/css/Typeahead.css';

@import './colors.css';

.plugin_container {
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 15px;
    min-height: 95vh;
}

.margin_top_row {
    margin-top: 1rem;
}

.margin_bottom_rowm {
    margin-bottom: 1rem;
}

.add_button {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    float: "right";
}

.add_button_disabled {
    color: var(--disabled--color);
    cursor: not-allowed;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    float: "right";
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.edit_header_icon_delete {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--declined--color)
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.phone_number {
    width: 100%
}

.phone_number_disabled {
    width: 100%;
    background-color: #e9ecef;
    opacity: 1;
}

.error_email input {
    border-color: var(--declined--color);
}

.error_div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.custom_switch .custom-control-label::before {
    cursor: pointer;
}

.custom_switch .custom-control-label::after {
    cursor: pointer;
}

.custom-control-input:checked~.custom-control-label::before {
    cursor: pointer;
}

.address_button {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
}

.address_col {
    text-align: right;
}

.address_card {
    margin-bottom: 1rem;
}